import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: '/' });

const getToken = (): string | undefined => cookies.get('token');

const request = async (url: string, method: string, body?: any, isFileUpload = false) => {
    const token = getToken();
    const headers: HeadersInit = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
    };

    if (!isFileUpload) {
        headers['Content-Type'] = 'application/json';
    }

    const requestOptions: RequestInit = {
        method,
        headers,
        body: body ? (isFileUpload ? body : JSON.stringify(body)) : undefined
    };

    try {
        const response = await fetch(url, requestOptions);
        return handleResponse(response);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const _get = (url: string) => request(url, 'GET');
export const _post = (url: string, body: any) => request(url, 'POST', body);
export const _put = (url: string, body: any) => request(url, 'PUT', body);
export const _delete = (url: string, body?: any) => request(url, 'DELETE', body);
export const _filePost = (url: string, body: any) => request(url, 'POST', body, true);

const handleResponse = async (response: Response) => {
    if (!response.ok) {
        if (response.status === 401) {
            // Handle logout scenario properly, e.g., redirect to login
            console.error("Unauthorized: Redirecting to login");
            return Promise.reject("Unauthorized access");
        }

        const errorResponse = await response.json().catch(() => ({}));
        return Promise.reject(errorResponse.error || errorResponse.message || "Unknown error occurred");
    }

    const jsonResponse = await response.json();
    if (jsonResponse?.success || jsonResponse?.code === 10 || jsonResponse?.code === "10") {
        return jsonResponse;
    }

    return Promise.reject(jsonResponse.message || jsonResponse.description || "Error");
};
